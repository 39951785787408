import swup from "../swup.js";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default () => ({
    selectors: {
        inElements: "[data-story-in-animation]",
    },
    inElements: null,
    inElementsVisible: null,
    inElementsHidden: null,

    init() {
        this.inElements = Array.from(
            this.$el.querySelectorAll(this.selectors.inElements),
        );

        this.$nextTick(() => {
            this.inElementsVisible = this.inElements.filter((element) =>
                this.isElementVisible(element),
            );

            this.inElementsHidden = this.inElements.filter(
                (element) => !this.isElementVisible(element),
            );

            // sort elements with passed value to attribute first
            this.inElementsVisible.sort((a, b) => {
                const aValue = a.getAttribute("data-story-in-animation");
                const bValue = b.getAttribute("data-story-in-animation");

                if (aValue === "" && bValue !== "") return 1;
                if (aValue !== "" && bValue === "") return -1;
                return 0;
            });

            this.setScene();
        });
    },

    isElementVisible(element) {
        return !!(
            element.offsetParent !== null &&
            window.getComputedStyle(element).display !== "none"
        );
    },

    destroy() {
        ScrollTrigger.killAll();
    },

    setScene() {
        gsap.set(this.inElementsVisible, { opacity: 0 });
        gsap.set(this.inElementsHidden, { opacity: 1 });
        this.initScrollTrigger();
    },

    initScrollTrigger() {
        ScrollTrigger.batch(this.inElementsVisible, {
            start: "top bottom-=50",
            once: true,
            onEnter: (batch) => {
                gsap.fromTo(
                    batch,
                    { y: 30 },
                    {
                        opacity: 1,
                        y: 0,
                        stagger: 0.1,
                        duration: 0.45,
                        ease: "circle.out",
                    },
                );
            },
        });
    },
});
